body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td {
  padding: 12px 0;
}

td:first-child {
  padding-left: 10px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

td:last-child {
  padding-right: 10px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.hidecell {
  display: none;
}

tr:hover .hidecell {
  display: block;
}
